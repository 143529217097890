import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';
import { DataTable } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import image from '../../../assets/Endo-CEF-1-small-v12.svg';

// image alts - so they do not repeat in mobile & desktop
const advancedOrRecurrentEndoAlt = "Patient Characteristics With Primary Advanced or Recurrent Endometrial Carcinoma in KEYNOTE-868";

const ENDO = () => (
    
    <ComponentWrapper>
    <StudyDesignOverview 
        title="Study Overview"
        description="
            <p>Phase 3, multicenter, randomized, double-blind, placebo-controlled trial in 810 patients with advanced or recurrent endometrial carcinoma.<sup>1</sup></p>
            <p>The study design included two separate cohorts based on MMR status; 222 (27%) patients were in the dMMR cohort and 588 (73%) were in the pMMR cohort.</p>" 
        data={[
        [
            {
                title: 'Patient Eligibility',
                description: `
                <h6>Key Inclusion Criteria</h6>
                <ul>
                    <li>Patients with advanced or recurrent endometrial carcinoma, including those with dMMR and pMMR tumors</li>
                    <li>Patients with measurable Stage III, measurable Stage IVA, Stage IVB or recurrent endometrial carcinoma (with or without measurable disease)</li>
                    <li>Patients had not received prior systemic therapy or had received prior chemotherapy in the adjuvant setting. Patients who had received prior adjuvant chemotherapy were only eligible if their chemotherapy-free interval was at least 12 months</li>
                </ul>

                <h6>Key Exclusion Criteria</h6>
                <ul>
                    <li>Patients with endometrial sarcoma, including carcinosarcoma, or patients with active autoimmune disease or a medical condition that required immunosuppression </li>
                </ul>

                <h6>Stratification Factors</h6>
                <ul>
                    <li>MMR status</li>
                    <li>ECOG PS (0 or 1 vs 2)</li>
                    <li>Prior adjuvant chemotherapy</li>
                </ul>
                `,
                type: 'box-gradient',
                colspan: 3
            }
        ],
        [
            {
                type: 'arrow-gray',
                colspan: 3
            }
        ],
        [
            {
                title: 'Randomized 1:1 (N=810)',
                colspan: 3
            }
        ],
    ]}
    />

    <StudyDesignArms title="Study Arms" arms={[
        {
            title: "KEYTRUDA + paclitaxel + carboplatin",
            data: [
                [
                    {
                        title: "pMMR (n=294) and dMMR (n=110)<br/>Combination Therapy<br/>6 cycles<sup>i</sup>",
                        description: [
                            {
                                text: 'KEYTRUDA 200&nbsp;mg IV Q3W',
                                textCenter: true
                            },
                            {
                                text: 'Paclitaxel 175&nbsp;mg/m<sup>2</sup> IV Q3W',
                                conditional: 'and',
                                textCenter: true
                            },
                            {
                                text: 'Carboplatin AUC 5&nbsp;mg/mL/min IV Q3W',
                                conditional: 'and',
                                textCenter: true
                            },
                        ],
                        type: 'box-multi-color-green'
                    }
                ],
                [
                    {
                        type: 'arrow-green'
                    }
                ],
                [
                    {
                        title: "Followed by Monotherapy (up to 14 cycles)",
                        description: [
                            {
                                text: 'KEYTRUDA 400&nbsp;mg IV Q6W',
                                textCenter: true
                            },
                        ],
                        type: 'box-multi-color-green'
                    }
                ],
                [
                    {
                        type: 'arrow-green'
                    }
                ],
                [
                    {
                        title: 'Treatment Duration',
                        description: `Treatment continued until disease progression, unacceptable toxicity, or a maximum of 20 cycles (up to approximately 24 months).`
                    }
                ]
            ]
        },
        {
            title: "Placebo + paclitaxel + carboplatin",
            data: [
                [
                    {
                        title: "pMMR (n=294) and dMMR (n=112)<br/>Combination Therapy<br/>6 cycles<sup>i</sup>",
                        description: [
                            {
                                text: `Placebo IV Q3W`,
                                textCenter: true
                            },
                            {
                                text: `Paclitaxel 175&nbsp;mg/m<sup>2</sup> IV Q3W`,
                                conditional: 'and',
                                textCenter: true
                            },
                            {
                                text: `Carboplatin AUC 5&nbsp;mg/mL/min IV Q3W`,
                                conditional: 'and',
                                textCenter: true
                            },                         
                        ],
                        type: 'box-multi-color-gray'
                    }
                ],
                [
                    {
                        type: 'arrow-gray'
                    }
                ],
                [
                    {
                        title: "Followed by Monotherapy (up to 14 cycles)",
                        description: [
                            {
                                text: 'Placebo IV Q6W',
                                textCenter: true
                            },
                        ],
                        type: 'box-multi-color-gray'
                    }
                ],
                [
                    {
                        type: 'arrow-gray'
                    }
                ],
                [
                    {
                        title: 'Treatment Duration',
                        description: `Treatment continued until disease progression, unacceptable toxicity, or a maximum of 20 cycles (up to approximately 24 months).`
                    }
                ]
            ]
        }
    ]} 
    />
    <StudyCopyBlock 
        description={`<p>Assessment of tumor status was performed every 9 weeks for the first 9 months and then every 12 weeks thereafter.</p>`}
    />
    <StudyCopyBlock 
        title="End Points"
        description={`
            <p><strong>Major Efficacy Outcome:</strong></p>
            <ul>
                <li>PFS<sup>j</sup></li>
            </ul>
            <p><strong>Additional Efficacy Outcome:</strong></p>
            <ul>
                <li>OS</li>
            </ul>
            <p>At the time of the PFS analysis, OS data were not mature with 12% deaths in the dMMR population and 17% deaths in the pMMR population.</p>

        `} 
    />
    <StudyCopyBlock 
        title="Patient Characteristics"
        description={`
            <p>Baseline Characteristics of Patients (N=810) Among Randomized Patients</p>
            <ul>
                <li>588 (73%) had pMMR tumor status</li>
                <li>222 (27%) had dMMR tumor status</li>
            </ul>
        `}
    />
    <DataTable 
        mobileImage={() => <img src={image} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={advancedOrRecurrentEndoAlt}/>}
        desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" 
        src="../../../assets/Endo-CEF-1-xlarge-v12.png"
        alt={advancedOrRecurrentEndoAlt}/>}
        limitation='All patients randomized at baseline:'
        footnotes={[
            {
                label:'i.',
                text: 'Patients with measurable disease who had Response Evaluation Criteria for Solid Tumors (RECIST)-defined stable disease or partial response at the completion of cycle 6 were permitted to continue receiving paclitaxel and carboplatin with KEYTRUDA or placebo for up to 10 cycles as determined by the investigator.'
            },
            {
                label:'j.',
                text: 'As assessed by the investigator according to RECIST 1.1'
            },
        ]}
        definitions="ECOG PS = Eastern Cooperative Oncology Group performance status; IV = intravenously; Q3W = every 3 weeks; AUC = area under the curve; Q6W = every 6 weeks; OS = overall survival; NOS = not otherwise specified."
    />

</ComponentWrapper>
);

export default ENDO
