import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import { StaticImage } from 'gatsby-plugin-image';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import {ComparisonTable, KMCurve, FeaturedStat, PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, NCCNcallout, PageSubSection, SecondaryCallout, TextBlock, PatientProfile, ReferencesBlock } from '../../components';
import ENDO from '../../components/StudyDesign/__study-design/advanced-or-recurrent-endo';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-868 - Clinical Trial Results & Study Design | HCP`,
    keywords: `keynote 868, keynote-868`,
    description: `Health care professionals may find information about KEYNOTE-868 including clinical study results, study design, and safety profile. Explore here.`,
    schemaJsonLD: [
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/advanced-or-recurrent-endometrial-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}',
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/advanced-or-recurrent-endometrial-carcinoma/","@type":"MedicalTrial","name":"KEYNOTE-868","description":"Clinical Findings from KEYNOTE⁠-⁠868/NRG⁠-⁠GY018","trialDesign":"Phase 3, multicenter, randomized, double-blind, placebo-controlled trial in 810 patients with advanced or recurrent endometrial carcinoma. The study design included two separate cohorts based on MMR status; 222 (27%) patients were in the dMMR cohort and 588 (73%) were in the pMMR cohort."}',
    ]
}

//from /src/util/data/indications.js
const indicationId = 1;
const jobCode = jobCodes[32].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'Advanced MSI⁠-⁠H/dMMR Endometrial Carcinoma', url: '/efficacy/advanced-endometrial-carcinoma/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/advanced-or-recurrent-endometrial-carcinoma/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};


const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;868/NRG&#8288;-&#8288;GY018' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;868/NRG&#8288;-&#8288;GY018' },
    { label: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)' },
    { label: 'Appropriate Patients' }
]

const NCCNcalloutData = {
    title: 'NCCN Guidelines® recommends pembrolizumab in combination with carboplatin/paclitaxel as NCCN Category 1 Preferred Option for primary (stage III–IV) or recurrent endometrial carcinoma (except for carcinosarcoma).<sup>2,k,l</sup>',
    showBadge: false,
};

const NCCNFootnotes = [
    {
        label: "k.",
        text: "For stage III or IVA with measurable disease or stage IVB with or without measurable disease."
    },
    {
        label: "l.",
        text: "Checkpoint inhibitors and/or monoclonal antibodies included in this regimen may be continued as maintenance therapy. Refer to original study protocol for maintenance therapy dosing schedules."
    }
];

const NCCNdefinitions = `
    <p>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in any way.</p>
    <p>Category 1 = based upon high-level evidence, there is uniform National Comprehensive Cancer Network® (NCCN®) consensus that the intervention is appropriate.</p>
    <p>Preferred intervention = Interventions that are based on superior efficacy, safety, and evidence; and, when appropriate, affordability.</p>
`;

const NCCNcalloutReferences = [
    {
        label: "2.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) Uterine Neoplasms V2.2024. National Comprehensive Cancer Network, Inc. 2024. Accessed March 12, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
];


const FeturedStatDefinitions = "PFS = progression-free survival; HR = hazard ratio; CI = confidence interval; dMMR = mismatch repair deficient; pMMR = mismatch repair proficient; MMR = mismatch repair."

const PFSComparisonFootnotes = [
     {
        label: "a.",
        text: "Based on interim PFS analysis; the information fractions for interim analyses were 49% for dMMR and 55% for pMMR."
    }, 
    {
        label: "b.",
        text: "Paciltaxel + carboplatin."
    }, 
    {
        label: "c.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "d.",
        text: "Based on the stratified log-rank test."
    },
]

const ComparissonTableDMMRFootnotes = [
    {
        label: "e.",
        text: "Based on interim PFS analysis; the information fractions for interim analyses were 49% for dMMR and 55% for pMMR."
    }, 
    {
        label: "f.",
        text: "Paclitaxel + carboplatin."
    },
    {
        label: "g.",
        text: "Based on the stratified Cox proportional hazard model."
    }, 
    {
        label: "h.",
        text: "Based on the stratified log-rank test."
    }
    
]

const ComparissonTableDMMRData = [
    [
        {
            legendTitle: 'KEYTRUDA + chemotherapy<sup>f</sup> (n=110)',
        },
        {
            legendTitle: 'Placebo + chemotherapy<sup>f</sup> (n=112)',
        }
    ],
    [
        {
            colspan: 2,
            centerText: true,
            hrCiP: 'HR<sup>g</sup>=0.30 (95% CI, 0.19&ndash;0.48); <i>P</i><sup>h</sup><0.0001'
        }
    ],
    [
        {
            label: 'Number (%) of patients with an event',
            dataPoint: '26/110 (24%)',
        },
        {
            label: 'Number (%) of patients with an event',
            dataPoint: '57/112 (51%)',
        }
    ],
    [
        {
            label: 'Median PFS in Months',
            dataSubPoint: 'NR (95% CI, 30.7–NR)',
        },
        {
            label: 'Median PFS in Months',
            dataSubPoint: '6.5 (95% CI, 6.4–8.7)',
        }
    ],
]

const ComparissonTableDMMR = {
    title: "PFS<sup>e</sup> in the dMMR Population in KEYNOTE&#8288;-&#8288;868",
    data: ComparissonTableDMMRData,
    footnotes: ComparissonTableDMMRFootnotes,
    definitions: "NR = not reached."
};

const patientProfileAaliyah = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/endo_patient.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Aaliyah",
    diagnosis: "Recurrent endometrial carcinoma",
    primaryCharacteristics: [
        {
            key: 'Age',
            value: '55',
            emphasize: false,
        },
        {
            key: 'Past Medical History',
            value: 'Grade II Stage IB endometrial carcinoma',
            emphasize: false,
        },
        {
            key: 'Surgical history',
            value: 'Total hysterectomy and bilateral salpingo-oophorectomy and pelvic lymph node dissection',
            emphasize: false,
        },
        {
            key: '18-month follow-up PET/CT scan of chest, abdomen and pelvis',
            value: 'A tumor mass in the pelvis with no evidence of distant metastases was seen',
            emphasize: false,
        },
        {
            key: 'Tumor MMR status',
            value: 'pMMR',
            emphasize: false,
        },
        {
            key: 'ECOG PS',
            value: '0',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: []
}

const pageReferences = [
    {
        label: "1.",
        text: "Eskander RN, Sill MW, Beffa L, et al. Pembrolizumab plus chemotherapy in advanced endometrial cancer. <i>N Engl J Med.</i> 2023;388(23):2159–2170. doi:10.1056/NEJMoa2302312"
    },
    {
        label: "2.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) Uterine Neoplasms v2.2024. National Comprehensive Cancer Network, Inc. 2024. Accessed March 12, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">KEYNOTE&#8288;-&#8288;868/NRG&#8288;-&#8288;GY018: Designed to independently study pMMR and dMMR populations in primary advanced and recurrent endometrial carcinoma<sup>1</sup></PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <PageSubSection title="Superior PFS for Adult Patients Randomized to KEYTRUDA + Paclitaxel and Carboplatin Followed by KEYTRUDA Alone vs Placebo + Paclitaxel and Carboplatin Followed by Placebo Alone in Both the pMMR and dMMR Populations">
                            </PageSubSection>
                            <PageSubSection title="PFS<sup>a</sup> in the pMMR Population (n=588)">
                                <FeaturedStat
                                    title="Reduction in risk of disease progression or death in patients with pMMR tumors with KEYTRUDA + chemotherapy<sup>b</sup> compared with placebo + chemotherapy<sup>b</sup>"
                                    statistic="40%"
                                    hr="HR<sup>c</sup>=0.60 (95% CI, 0.46&ndash;0.78); <i>P</i><sup>d</sup><0.0001"
                                />
                                <KMCurve
                                    image={() => <StaticImage 
                                    src="../../assets/endo-KN-868_pMMR_Curve-v8.png"
                                    placeholder="none"
                                    alt="Kaplan-Meier Curve for PFS in pMMR Patients With Primary Advanced or Recurrent Endometrial Carcinoma" /> }
                                    title="pMMR Population: Kaplan-Meier Curve for PFS<sup>a</sup>"
                                    hr="HR<sup>c</sup>=0.60 (95% CI, 0.46–0.78); <i>P</i><sup>d</sup><0.0001"
                                />
                                <ComparisonTable
                                    title="PFS<sup>a</sup> in the pMMR Population in KEYNOTE&#8288;-&#8288;868"
                                    footnotes={PFSComparisonFootnotes}
                                    definitions={FeturedStatDefinitions}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA + chemotherapy<sup>b</sup> (n=294)',
                                                },
                                                {
                                                    legendTitle: 'Placebo + chemotherapy<sup>b</sup> (n=294)',
                                                },
                                            ],
                                            [
                                                {
                                                    colspan: 2,
                                                    centerText: true,
                                                    hrCiP: 'HR<sup>c</sup>=0.60 (95% CI, 0.46–0.78); <i>P</i><sup>d</sup><0.0001'
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Number (%) of patients with an event',
                                                    dataPoint: '91/294 (31%)',
                                                },
                                                {
                                                    label: 'Number (%) of patients with an event',
                                                    dataPoint: '124/294 (42%)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median PFS in Months',
                                                    dataSubPoint: '11.1 (95% CI, 8.7–13.5)',
                                                },
                                                {
                                                    label: 'Median PFS in Months',
                                                    dataSubPoint: '8.5 (95% CI, 7.2–8.8)',
                                                },
                                            ],
                                        ]
                                    }
                                />
                            </PageSubSection>
                            <PageSubSection title="PFS<sup>e</sup> in the dMMR Population (n=222)">
                                <FeaturedStat
                                    title="Reduction in risk of disease progression or death in patients with dMMR tumors with KEYTRUDA + chemotherapy<sup>f</sup> compared with placebo + chemotherapy<sup>f</sup>"
                                    statistic="70%"
                                    hr="HR<sup>g</sup>=0.30 (95% CI, 0.19&ndash;0.48); <i>P</i><sup>h</sup><0.0001"
                                />
                                <KMCurve 
                                    image={() => <StaticImage 
                                        src="../../assets/endo-KN-868_dMMR_Curve-v9.png"
                                        placeholder="none"
                                        alt="Kaplan-Meier Curve for PFS in dMMR Patients With Primary Advanced or Recurrent Endometrial Carcinoma" 
                                    /> }
                                    title="dMMR Population: Kaplan-Meier Curve for PFS<sup>e</sup>"
                                    hr="HR<sup>g</sup>=0.30 (95% CI, 0.19&ndash;0.48); <em>P</em><sup>h</sup><0.0001"
                                />
                                <ComparisonTable  { ...ComparissonTableDMMR} />
                            </PageSubSection>
                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <ENDO />
                            </PageSubSection>
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[2].label}>
                            <NCCNcallout
                                data={NCCNcalloutData}
                                footnotes={NCCNFootnotes}
                                definitions={NCCNdefinitions}
                                references={NCCNcalloutReferences}
                            />
                        </PageSection>

                        <PageSection title={anchorLinkModelData[3].label}>
                            <PageSubSection title="Hypothetical Patient With Recurrent Endometrial Carcinoma Based on Those Studied in KEYNOTE&#8288;-&#8288;868">

                                <PatientProfile
                                    {...patientProfileAaliyah} 
                                />
                            </PageSubSection>
                        </PageSection>

                    </TemplateColumn>
                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>
                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>

                <PageSection bgColor="mist">
                    <ReferencesBlock references={pageReferences} />
                </PageSection>
            </div>
        </DefaultLayout>
    );
};

export default Page;
